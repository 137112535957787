import * as RadixCheckbox from '@radix-ui/react-checkbox';
import clsx from 'clsx';

import type { FC } from 'react';

import type { ClassNameMap } from '@domains/shared';

import styles from './FilterChip.module.scss';

export type FilterChipSize = 'small' | 'medium';

const sizeToClassNameMap: ClassNameMap<FilterChipSize> = {
    small: styles.isSmall,
    medium: styles.isMedium,
};

export type FilterChipProps = Readonly<{
    label: string;
    size: FilterChipSize;
    isChecked: boolean;
    onChange: (value: boolean) => void;
    className?: string;
    hasFixedWidth?: boolean;
}>;

export const FilterChip: FC<FilterChipProps> = (props) => {
    const { label, size, isChecked, onChange, className, hasFixedWidth = false } = props;

    return (
        <RadixCheckbox.Root
            checked={isChecked}
            onCheckedChange={onChange}
            className={clsx(
                styles.root,
                className,
                sizeToClassNameMap[size],
                hasFixedWidth && styles.hasFixedWidth,
            )}
        >
            {label}
        </RadixCheckbox.Root>
    );
};
