import { match } from 'ts-pattern';

import { LocalityEnum, ProjectPlanEnum } from '@api/generated';

import { makeApartmentsPlanRoute, makeBuildingPlanRoute } from './route';

export const projectLocalityLabelByLocality: Record<LocalityEnum, string> = {
    [LocalityEnum.Tikhaia]: 'Тихая',
    [LocalityEnum.Zarya]: 'Заря',
    [LocalityEnum.VtoraiaRechka]: 'Вторая речка',
    [LocalityEnum.PervaiaRechka]: 'Первая речка',
    [LocalityEnum.Centr]: 'Центр',
    [LocalityEnum.Churkin]: 'Чуркин',
    [LocalityEnum.Zmeinka]: 'Змеинка',
    [LocalityEnum.Lugovaja]: 'Луговая',
    [LocalityEnum.Dalhimprom]: 'Дальхимпром',
    [LocalityEnum.Yegersheld]: 'Эгершельд',
    [LocalityEnum.Nejbuta]: 'Нейбута',
    [LocalityEnum.TretiaRabochaja]: 'Третья рабочая',
    [LocalityEnum.Baljaeva]: 'Баляева',
    [LocalityEnum.Russkij]: 'Русский',
    [LocalityEnum.SnegovajaPad]: 'Снеговая Падь',
    [LocalityEnum.Patrokl]: 'Патрокл',
    [LocalityEnum.Bam]: 'БАМ',
    [LocalityEnum.Sadgorod]: 'Садгород',
    [LocalityEnum.Sedanka]: 'Седанка',
    [LocalityEnum.DalniyPrigorod]: 'Дальний пригород',
    [LocalityEnum.Nadezhdinskij]: 'Надеждинский',
    [LocalityEnum.Artjom]: 'Артём',
};

export const makeProjectPlanRoute = (slug: string, planKind: ProjectPlanEnum): string =>
    match(planKind)
        .with(ProjectPlanEnum.Building, () => makeBuildingPlanRoute)
        .with(ProjectPlanEnum.Floors, () => makeApartmentsPlanRoute)
        .exhaustive()(slug);
