import type { SliderProps } from '@radix-ui/react-slider';
import {
    Range as SliderRange,
    Root as SliderRoot,
    Thumb as SliderThumb,
    Track as SliderTrack,
} from '@radix-ui/react-slider';

import type { FC } from 'react';

import styles from './RangeSlider.module.scss';

type RangeSliderProps = SliderProps;

export const RangeSlider: FC<RangeSliderProps> = (props) => (
    <SliderRoot className={styles.root} {...props}>
        <SliderTrack className={styles.track}>
            <SliderRange className={styles.range} />
        </SliderTrack>
        <SliderThumb className={styles.thumb} />
        <SliderThumb className={styles.thumb} />
    </SliderRoot>
);
