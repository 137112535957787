import { type EventCallable, type Store } from 'effector';

import { F, O, Predicate as P, S } from '@feip-internal/fp-ts';

import type {
    FloatRangeFilter,
    FloatStringRangeFilter,
    IntegerRangeFilter,
    IntegerStringRangeFilter,
    MultiselectFilterOption,
} from '@domains/filters';

import { floatRegExp, integerRegExp } from '@utils/regexp';

export type MultiselectFilterState<Value extends string> =
    readonly MultiselectFilterOption<Value>[];

export type SetOptionSelectedEvent<Value extends string> = Readonly<{
    value: Value;
    isSelected: boolean;
}>;

export type MultiselectFilterModel<Value extends string> = Readonly<{
    initialize: EventCallable<readonly MultiselectFilterOption<Value>[]>;
    reset: EventCallable<void>;
    clear: EventCallable<void>;
    $state: Store<MultiselectFilterState<Value>>;
    setOptionSelected: EventCallable<SetOptionSelectedEvent<Value>>;
}>;

export type SetRangeValueEvent = Readonly<string>;

export type IntegerRangeFilterState = IntegerStringRangeFilter;
export type IntegerRangeFilterModel = Readonly<{
    initialize: EventCallable<IntegerRangeFilter>;
    reset: EventCallable<void>;
    clear: EventCallable<void>;
    $state: Store<IntegerRangeFilterState>;
    $parsedState: Store<IntegerRangeFilter>;
    setMinRangeValue: EventCallable<SetRangeValueEvent>;
    setMaxRangeValue: EventCallable<SetRangeValueEvent>;
}>;

export type FloatRangeFilterState = FloatStringRangeFilter;
export type FloatRangeFilterModel = Readonly<{
    initialize: EventCallable<FloatRangeFilter>;
    reset: EventCallable<void>;
    clear: EventCallable<void>;
    $state: Store<FloatRangeFilterState>;
    $parsedState: Store<FloatRangeFilter>;
    setMinRangeValue: EventCallable<SetRangeValueEvent>;
    setMaxRangeValue: EventCallable<SetRangeValueEvent>;
}>;

export const isFloatString = (value: string) => floatRegExp.test(value);

export const isIntegerString = (value: string): boolean => integerRegExp.test(value);

export const parseIntegerWithFallback = (
    value: string,
    fallback: number,
    min: number,
    max: number,
) =>
    F.pipe(
        value,
        O.fromPredicate(P.not(S.isEmpty)),
        O.map(Number),
        O.filter(Number.isSafeInteger),
        O.map((parsedValue) => Math.min(Math.max(parsedValue, min), max)),
        O.getOrElse(F.constant(fallback)),
    );

export const parseFloatWithFallback = (value: string, fallback: number, min: number, max: number) =>
    F.pipe(
        value,
        O.fromPredicate(P.not(S.isEmpty)),
        O.map(Number),
        O.filter(Number.isFinite),
        O.map((parsedValue) => Math.min(Math.max(parsedValue, min), max)),
        O.getOrElse(F.constant(fallback)),
    );
