import { readonly } from 'patronum';

import { createEvent, createStore, type Domain, sample } from 'effector';

import * as RX from 'fp-ts-contrib/RegExp';
import { F, RA, S } from '@feip-internal/fp-ts';

import type {
    FloatRangeFilter,
    FloatStringRangeFilter,
    MultiselectFilterOption,
} from '@domains/filters';
import { type IntegerRangeFilter, type IntegerStringRangeFilter } from '@domains/filters';

import { integerRegExp } from '@utils/regexp';

import type { FloatRangeFilterModel } from './lib';
import {
    type IntegerRangeFilterModel,
    isFloatString,
    type MultiselectFilterModel,
    type MultiselectFilterState,
    parseFloatWithFallback,
    parseIntegerWithFallback,
    type SetOptionSelectedEvent,
    type SetRangeValueEvent,
} from './lib';

export const createMultiselectFilter = <Value extends string>(
    domain: Domain,
): MultiselectFilterModel<Value> => {
    const $initialState = createStore<MultiselectFilterState<Value>>([], { domain });
    const $state = createStore<MultiselectFilterState<Value>>([], { domain });

    const initialize = createEvent<readonly MultiselectFilterOption<Value>[]>({ domain });
    const reset = createEvent({ domain });
    const clear = createEvent({ domain });
    const setOptionSelected = createEvent<SetOptionSelectedEvent<Value>>({ domain });

    sample({ clock: initialize, target: [$initialState, $state] });

    sample({ clock: reset, source: $initialState, target: $state });

    sample({ clock: clear, target: $state.reinit });

    sample({
        clock: setOptionSelected,
        source: $state,
        fn: (state, clock) =>
            F.pipe(
                state,
                RA.map((option) =>
                    option.value === clock.value
                        ? { ...option, isSelected: clock.isSelected }
                        : option,
                ),
            ),
        target: $state,
    });

    return {
        initialize,
        reset,
        clear,
        $state: readonly($state),
        setOptionSelected,
    };
};

export const createIntegerRangeFilter = (domain: Domain): IntegerRangeFilterModel => {
    const $initialState = createStore<IntegerStringRangeFilter>(
        {
            kind: 'integer',
            availableMin: 0,
            availableMax: 0,
            selectedMin: '',
            selectedMax: '',
        },
        { domain },
    );

    const $state = createStore<IntegerStringRangeFilter>(
        {
            kind: 'integer',
            availableMin: 0,
            availableMax: 0,
            selectedMin: '',
            selectedMax: '',
        },
        { domain },
    );

    const $parsedState = $state.map(
        (state): IntegerRangeFilter => ({
            ...state,
            selectedMin: parseIntegerWithFallback(
                state.selectedMin,
                state.availableMin,
                state.availableMin,
                state.availableMax,
            ),
            selectedMax: parseIntegerWithFallback(
                state.selectedMax,
                state.availableMax,
                state.availableMin,
                state.availableMax,
            ),
        }),
    );

    const initialize = createEvent<IntegerRangeFilter>({ domain });
    const reset = createEvent({ domain });
    const clear = createEvent({ domain });
    const setMinRangeValue = createEvent<SetRangeValueEvent>({ domain });
    const setMaxRangeValue = createEvent<SetRangeValueEvent>({ domain });

    sample({
        clock: initialize,
        fn: (filter: IntegerRangeFilter): IntegerStringRangeFilter => ({
            ...filter,
            availableMin: filter.availableMin,
            availableMax: filter.availableMax,
            selectedMin: String(filter.selectedMin ?? filter.availableMin),
            selectedMax: String(filter.selectedMax ?? filter.availableMax),
        }),
        target: [$initialState, $state],
    });

    sample({ clock: reset, source: $initialState, target: $state });

    sample({
        clock: setMinRangeValue,
        source: $state,
        filter: (_state, clock) => S.isEmpty(clock) || RX.test(integerRegExp)(clock),
        fn: (state, clock) => ({ ...state, selectedMin: clock }),
        target: $state,
    });

    sample({
        clock: setMaxRangeValue,
        source: $state,
        filter: (_state, clock) => S.isEmpty(clock) || RX.test(integerRegExp)(clock),
        fn: (state, clock) => ({ ...state, selectedMax: clock }),
        target: $state,
    });

    return {
        initialize,
        reset,
        clear,
        $state: readonly($state),
        $parsedState: readonly($parsedState),
        setMinRangeValue,
        setMaxRangeValue,
    };
};

export const createFloatRangeFilter = (domain: Domain): FloatRangeFilterModel => {
    const $initialState = createStore<FloatStringRangeFilter>(
        {
            kind: 'float',
            availableMin: 0,
            availableMax: 0,
            selectedMin: '',
            selectedMax: '',
        },
        { domain },
    );

    const $state = createStore<FloatStringRangeFilter>(
        {
            kind: 'float',
            availableMin: 0,
            availableMax: 0,
            selectedMin: '',
            selectedMax: '',
        },
        { domain },
    );

    const $parsedState = $state.map(
        (state): FloatRangeFilter => ({
            ...state,
            selectedMin: parseFloatWithFallback(
                state.selectedMin,
                state.availableMin,
                state.availableMin,
                state.availableMax,
            ),
            selectedMax: parseFloatWithFallback(
                state.selectedMax,
                state.availableMax,
                state.availableMin,
                state.availableMax,
            ),
        }),
    );

    const initialize = createEvent<FloatRangeFilter>({ domain });
    const reset = createEvent({ domain });
    const clear = createEvent({ domain });
    const setMinRangeValue = createEvent<SetRangeValueEvent>({ domain });
    const setMaxRangeValue = createEvent<SetRangeValueEvent>({ domain });

    sample({
        clock: initialize,
        fn: (filter: FloatRangeFilter): FloatStringRangeFilter => ({
            ...filter,
            availableMin: filter.availableMin,
            availableMax: filter.availableMax,
            selectedMin: String(filter.selectedMin ?? filter.availableMin),
            selectedMax: String(filter.selectedMax ?? filter.availableMax),
        }),
        target: [$initialState, $state],
    });

    sample({ clock: reset, source: $initialState, target: $state });

    sample({
        clock: setMinRangeValue,
        source: $state,
        filter: (_state, clock) => S.isEmpty(clock) || isFloatString(clock),
        fn: (state, clock) => ({ ...state, selectedMin: clock }),
        target: $state,
    });

    sample({
        clock: setMaxRangeValue,
        source: $state,
        filter: (_state, clock) => S.isEmpty(clock) || isFloatString(clock),
        fn: (state, clock) => ({ ...state, selectedMax: clock }),
        target: $state,
    });

    return {
        initialize,
        reset,
        clear,
        $state: readonly($state),
        $parsedState: readonly($parsedState),
        setMinRangeValue,
        setMaxRangeValue,
    };
};
