import { NumericFormat } from 'react-number-format';

import type { FC } from 'react';

import { useUnit } from 'effector-react';

import type { FloatRangeFilterModel } from '@stores/filters/lib';

import { RangeSlider } from '@components/common/RangeSlider/RangeSlider';

import styles from './RangeFilter.module.scss';

export type RangeFilterProps = Readonly<{
    suffix?: string;
    label: string;
    model: FloatRangeFilterModel;
    handleApplyingFilters?: () => void;
}>;

export const FloatRangeFilter: FC<RangeFilterProps> = (props) => {
    const { suffix, label, model, handleApplyingFilters } = props;

    const [state, parsedState, setMin, setMax] = useUnit([
        model.$state,
        model.$parsedState,
        model.setMinRangeValue,
        model.setMaxRangeValue,
    ]);

    const handleSliderValueChange = (value: readonly number[]) => {
        if (value.length === 2) {
            setMin(String(value[0]));
            setMax(String(value[1]));
            handleApplyingFilters?.();
        }
    };

    return (
        <div className={styles.root}>
            <span className={styles.title}>{label}</span>

            <div className={styles.inputs}>
                <NumericFormat
                    value={state.selectedMin}
                    valueIsNumericString
                    allowLeadingZeros={false}
                    allowNegative={false}
                    thousandSeparator=" "
                    suffix={suffix}
                    onValueChange={({ value }) => setMin(value)}
                    className={styles.input}
                />

                <NumericFormat
                    value={state.selectedMax}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    thousandSeparator=" "
                    suffix={suffix}
                    onValueChange={({ value }) => setMax(value)}
                    className={styles.input}
                />
            </div>

            <div className={styles.range}>
                <RangeSlider
                    value={[
                        parsedState.selectedMin ?? parsedState.availableMin,
                        parsedState.selectedMax ?? parsedState.availableMax,
                    ]}
                    onValueChange={handleSliderValueChange}
                    min={parsedState.availableMin}
                    max={parsedState.availableMax}
                    step={1}
                    minStepsBetweenThumbs={1}
                    aria-label={label}
                />
            </div>
        </div>
    );
};
