import type { ParsedUrlQuery } from 'querystring';

import {
    Eq,
    F,
    O,
    Predicate as P,
    RA,
    RNEA,
    ReadonlyRecord as RR,
    ReadonlySet as RS,
    S,
} from '@feip-internal/fp-ts';

import type { FloatRangeFilter, IntegerRangeFilter } from '@domains/filters';

import { type MultiselectFilterState } from '@stores/filters/lib';

import { LocalityEnum, RoomsFilterEnum, StatusesFilterEnum } from '@api/generated';

import { floatRegExp, integerRegExp } from './regexp';

export const serializeMultiselectFilterToQuery = <T extends string>(
    name: string,
    filter: MultiselectFilterState<T>,
    shouldSerializeUnselected = false,
): ParsedUrlQuery =>
    F.pipe(
        filter,
        RA.filterMap((option) =>
            option.isSelected === !shouldSerializeUnselected ? O.some(option.value) : O.none,
        ),
        RA.intercalate(S.Monoid)(','),
        O.fromPredicate(P.not(S.isEmpty)),
        O.match(
            () => RR.empty,
            (value) => RR.singleton(name, value),
        ),
    );

const serializeRangeFilterValueToQuery = (
    name: string,
    value: number | null,
    limit: number,
): ParsedUrlQuery =>
    value === null || value === limit ? RR.empty : RR.singleton(name, String(value));

export const serializeIntegerRangeFilterToQuery = (
    nameMin: string,
    nameMax: string,
    filter: IntegerRangeFilter | FloatRangeFilter,
): ParsedUrlQuery => ({
    ...serializeRangeFilterValueToQuery(nameMin, filter.selectedMin, filter.availableMin),
    ...serializeRangeFilterValueToQuery(nameMax, filter.selectedMax, filter.availableMax),
});

export const parseTypedMultiselectFilterFromQuery =
    <T extends string>(name: string, allowedValues: ReadonlySet<T>) =>
    (query: ParsedUrlQuery): O.Option<RNEA.ReadonlyNonEmptyArray<T>> =>
        F.pipe(
            query,
            RR.lookup(name),
            O.filter(S.isString),
            O.chain(
                F.flow(
                    S.split(','),
                    RA.filterMap((value) =>
                        RS.elem(S.Eq)(value, allowedValues) ? O.some(value as T) : O.none,
                    ),
                    RNEA.fromReadonlyArray,
                ),
            ),
        );

export const parseUntypedMultiselectFilterFromQuery =
    (name: string) =>
    (query: ParsedUrlQuery): O.Option<RNEA.ReadonlyNonEmptyArray<string>> =>
        F.pipe(
            query,
            RR.lookup(name),
            O.filter(S.isString),
            O.chain(F.flow(S.split(','), RNEA.fromReadonlyArray)),
        );

export const parseIntegerRangeFilterValueFromQuery =
    (name: string) =>
    (query: ParsedUrlQuery): O.Option<number> =>
        F.pipe(
            query,
            RR.lookup(name),
            O.filter(S.isString),
            O.filter((value) => integerRegExp.test(value)),
            O.map(Number),
        );

export const parseFloatRangeFilterValueFromQuery =
    (name: string) =>
    (query: ParsedUrlQuery): O.Option<number> =>
        F.pipe(
            query,
            RR.lookup(name),
            O.filter(S.isString),
            O.filter((value) => floatRegExp.test(value)),
            O.map(Number),
        );

export const parseRoomsNumberFilterFormQuery =
    parseTypedMultiselectFilterFromQuery<RoomsFilterEnum>(
        'roomsNumber',
        RS.fromReadonlyArray<RoomsFilterEnum>(Eq.contramap(F.identity)(S.Eq))([
            RoomsFilterEnum.Studio,
            RoomsFilterEnum.One,
            RoomsFilterEnum.Two,
            RoomsFilterEnum.ThreeOrMore,
        ]),
    );

export const parseLocalityFilterPayloadFromQuery =
    parseTypedMultiselectFilterFromQuery<LocalityEnum>(
        'locality',
        RS.fromReadonlyArray<LocalityEnum>(Eq.contramap(F.identity)(S.Eq))([
            LocalityEnum.Artjom,
            LocalityEnum.Baljaeva,
            LocalityEnum.Bam,
            LocalityEnum.Centr,
            LocalityEnum.Churkin,
            LocalityEnum.Dalhimprom,
            LocalityEnum.DalniyPrigorod,
            LocalityEnum.Lugovaja,
            LocalityEnum.Nadezhdinskij,
            LocalityEnum.Nejbuta,
            LocalityEnum.Patrokl,
            LocalityEnum.PervaiaRechka,
            LocalityEnum.Russkij,
            LocalityEnum.Sadgorod,
            LocalityEnum.Sedanka,
            LocalityEnum.SnegovajaPad,
            LocalityEnum.Tikhaia,
            LocalityEnum.TretiaRabochaja,
            LocalityEnum.VtoraiaRechka,
            LocalityEnum.Yegersheld,
            LocalityEnum.Zarya,
            LocalityEnum.Zmeinka,
        ]),
    );

export const parseExcludedStatusesFilterPayloadFromQuery = F.flow(
    parseTypedMultiselectFilterFromQuery<StatusesFilterEnum>(
        'excludedStatuses',
        RS.fromReadonlyArray<StatusesFilterEnum>(Eq.contramap(F.identity)(S.Eq))([
            StatusesFilterEnum.Sold,
            StatusesFilterEnum.Booked,
        ]),
    ),
);

export const parseProjectFilterPayloadFromQuery = parseUntypedMultiselectFilterFromQuery('project');

export const parseCompletionYearFilterPayloadFromQuery =
    parseUntypedMultiselectFilterFromQuery('completionYear');

export const parseAdvantagesFilterPayloadFromQuery =
    parseUntypedMultiselectFilterFromQuery('advantages');

export const parsePriceRangeMinFilterPayload =
    parseIntegerRangeFilterValueFromQuery('priceRangeMin');
export const parsePriceRangeMaxFilterPayload =
    parseIntegerRangeFilterValueFromQuery('priceRangeMax');

export const parseAreaRangeMinFilterPayload = parseFloatRangeFilterValueFromQuery('areaRangeMin');
export const parseAreaRangeMaxFilterPayload = parseFloatRangeFilterValueFromQuery('areaRangeMax');
